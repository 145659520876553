import { useEffect } from "react";
import { PrivacyPageContent } from "../components";
import { PRIVACY_POLICY_CONTENT } from "../utils/constants";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, []);
  return (
    <PrivacyPageContent
      content={PRIVACY_POLICY_CONTENT}
      title="Privacy, Cookies & Confidentiality"
    />
  );
};

export default Privacy;
