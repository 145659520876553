import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
  position: relative;
  width: 60vw;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto; // Maintain aspect ratio
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: 0 0 250px rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    box-shadow: none;
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  /* background-color: rgba(255, 255, 255, 0.5); // Semi-transparent white circle */
  // border-radius: 50%; // Circular shape
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  height: 100%; // Circle height as 30% of video height
  width: 100%; // Circle width as 30% of video height
  display: ${({ isPlaying }) => (isPlaying ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  background-image: url(${({$videoThumbnailSrc}) => $videoThumbnailSrc});
  background-repeat: no-repeat;
  background-position: center;
  background-size: ${({ $videoThumbnailSrc }) =>
    $videoThumbnailSrc ? "100%" : "0%"};
`;

const MainIntroVideo = ({ videoUrl, altVideoUrl, videoThumbnailSrc }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (videoRef.current?.paused) {
                videoRef.current.muted = true;
                videoRef.current.play();
                setIsPlaying(true);
            }
        }, 4000);

        return () => clearTimeout(timer);

    }, []);

    const togglePlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
            videoRef.current.muted = false;
        }
    };

    return (
        <VideoContainer>
            <StyledVideo
                ref={videoRef}
                onClick={togglePlay}
            >
                <source src={videoUrl} type="video/webm" />
                <source src={altVideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </StyledVideo>
            <PlayButton onClick={togglePlay} isPlaying={isPlaying} $videoThumbnailSrc={videoThumbnailSrc}/>
        </VideoContainer>
    );
};

export default MainIntroVideo;
