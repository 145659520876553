import React from "react";
import { useMediaQuery } from "react-responsive";

import { BENEFITS_DESCRIPTION, getHomePageBenefits } from "../utils/constants";
import { BenefitsDescription, PostContent } from "../components";
import BaseLanding from "./BaseLanding";

function Home() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const benefitsList = getHomePageBenefits(isMobile);
  const { part1, part2 } = BENEFITS_DESCRIPTION;

  return (
    <BaseLanding
      descriptionContent={() => (
        <BenefitsDescription part1={part1} part2={part2} />
      )}
      benefitsList={benefitsList}
      postContent={() => <PostContent />}
      isHomePage={true}
    />
  );
}

export default Home;
