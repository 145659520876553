import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import {
  AddedToWaitListMessage,
  ErrorPopup,
  Footer,
  Header,
  JoinToWaitList,
  PrimaryButton,
  PrimaryInput,
} from "../components";
import Benefits from "../components/Benefits";
import Preview from "../components/Preview";
import { EMAIL_REGEX } from "../utils/constants";
import {
  FORCE_CORPORATE_EMAILS,
  addEmailToWaitList,
} from "../services/waitList";
import { validateResetToken, verifyEmail } from "../services/auth";
import { AppContext } from "../context";
import SuggestionPopup from "../components/SuggestionPopup";

function Landing({
  descriptionContent,
  benefitsList,
  postContent,
  isHomePage,
  titleImageSrc,
  videoThumbnailSrc,
  whiteListButtonText,
}) {
  let {
    resetToken,
    extensionId,
    verifyEmail: email,
    verificationCode,
    userId,
    isUserNotCreateFirstCampaign,
    isReadyToLaunchCampaign,
    campaignId,
    isQaEnv,
  } = useContext(AppContext);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isDescriptionVisible, setsDescriptionVisible] = useState(true);
  const [recordsCount, setRecordsCount] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isOpenCampaignsPage = isUserNotCreateFirstCampaign && userId;
  const isOpenCampaignDetailsPage =
    isReadyToLaunchCampaign && userId && campaignId;
  const goTo = (id) => {
    if (!id) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const validateEmail = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const joinToWaitList = async (value) => {
    if (value === "") {
      return;
    }

    let invalidEmailMessage;
    if (!validateEmail(value)) {
      invalidEmailMessage = "Invalid email address";
    } else if (
      (value.toString().toLowerCase().endsWith("gmail.com") ||
        value.toString().toLowerCase().endsWith("yahoo.com") ||
        value.toString().toLowerCase().endsWith("hotmail.com") ||
        value.toString().toLowerCase().endsWith("outlook.com") ||
        value.toString().toLowerCase().endsWith("mail.com") ||
        value.toString().toLowerCase().endsWith("icloud.com")) &&
      FORCE_CORPORATE_EMAILS
    ) {
      invalidEmailMessage = "Please use your company email address";
    }

    if (invalidEmailMessage) {
      setPopupMessage(invalidEmailMessage);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }

    const response = await addEmailToWaitList(value);

    if (!response.success) {
      setPopupMessage(response.message);
      setIsShowPopup(true);
      setTimeout(() => {
        setPopupMessage("");
        setIsShowPopup(false);
      }, 2000);
      return;
    }
    setsDescriptionVisible(false);
    setRecordsCount(response.recordsCount);
    goTo();
  };

  const sendMessageToExtension = (message) => {
    if (!extensionId) {
      setIsExtensionInstalled(false);
      return;
    }

    try {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage(extensionId, message);
    } catch (error) {
      setIsExtensionInstalled(false);
      console.log(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    if (resetToken && extensionId) {
      const validateToken = async () => {
        const response = await validateResetToken(resetToken, isQaEnv);
        if (!response.success) {
          sendMessageToExtension({
            message: "show-error-message",
            data: {
              message:
                "We’re sorry, but we’re unable to reset your password. Please try again",
            },
          });
          return;
        }
        sendMessageToExtension({
          message: "open-reset-iframe",
          data: {
            invited: response.invited,
            resetToken,
            windowType: "popup",
          },
        });

        const url = new URL(window.location.href);
        url.searchParams.delete("rt");
        window.history.replaceState({}, "", url);
      };

      validateToken();
    }
  }, [resetToken]);

  useEffect(() => {
    if (email && verificationCode && extensionId) {
      const verifyUser = async () => {
        const response = await verifyEmail(
          { email, code: verificationCode },
          isQaEnv
        );
        if (!response.success) {
          sendMessageToExtension({
            message: "show-error-message",
            data: {
              message:
                response.message ||
                "We’re sorry, but we’re unable to confirm your email. Please try again",
            },
          });
          return;
        }
        sendMessageToExtension({
          message: "open-reach-tab",
        });
      };

      verifyUser();
    }
  }, [email, verificationCode]);

  function extensionCheckRequired() {
    return isOpenCampaignsPage || isOpenCampaignDetailsPage;
  }

  const isExtensionCheckRequired = extensionCheckRequired();

  const { isExtensionInstalled, setIsExtensionInstalled } =
    useExtensionInstalled(postMessageEventHandler, isExtensionCheckRequired);

  function postMessageEventHandler(event) {
    if (event?.data?.isExtensionInstalled !== undefined) {
      setIsExtensionInstalled(event.data.isExtensionInstalled);
    }
  }

  function useExtensionInstalled(
    postMessageEventHandler,
    isExtensionCheckRequired
  ) {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(null);

    useEffect(() => {
      if (isExtensionCheckRequired) {
        window.addEventListener("message", postMessageEventHandler);

        try {
          sendMessageToExtension({
            message: "is-extension-installed",
          });
        } catch (error) {}

        return () => {
          window.removeEventListener("message", postMessageEventHandler);
        };
      }
    }, [postMessageEventHandler, isExtensionCheckRequired]);

    return {
      isExtensionInstalled,
      setIsExtensionInstalled,
    };
  }

  useEffect(() => {
    if (isExtensionInstalled) {
      if (isOpenCampaignsPage) {
        sendMessageToExtension({
          message: "open-campaigns-user-not-create-first-campaign",
        });
      } else if (isOpenCampaignDetailsPage) {
        sendMessageToExtension({
          message: "open-campaign-details-ready-to-launch",
          data: {
            campaignId,
          },
        });
      }

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }, [isExtensionInstalled]);

  return (
    <PageContainer>
      {isExtensionInstalled === false && (
        <SuggestionPopup
          imageSrc={"/images/sad-computer.png"}
          imageAlt={"sad computer"}
          title={"We can’t find the Reach extension"}
          description={PopupDescriptionText()}
          styles={{
            titleStyles: {
              fontColor: "#ff0000",
            },
          }}
        />
      )}
      <Header
        isMobile={isMobile}
        recordsCount={recordsCount}
        goToJoin={() => goTo("enter-email-to-join")}
      />
      <Preview
        isMobile={isMobile}
        isDescriptionVisible={isDescriptionVisible}
        videoThumbnailSrc={videoThumbnailSrc}
        isHomePage={isHomePage}
      >
        {recordsCount ? (
          <AddedToWaitListMessage recordsCount={recordsCount} color="white" />
        ) : isMobile ? (
          <PrimaryButton
            label={whiteListButtonText}
            isSmall={true}
            onClick={() => goTo("enter-email-to-join")}
          ></PrimaryButton>
        ) : (
          <PrimaryInput
            placeholder="Enter your email address"
            buttonText={whiteListButtonText}
            isBordered={false}
            isShadowed={false}
            onConfirm={joinToWaitList}
          />
        )}
      </Preview>
      <Benefits
        descriptionContent={descriptionContent}
        benefitsList={benefitsList}
        titleImageSrc={titleImageSrc}
        postContent={postContent}
      />
      <JoinToWaitList
        isMobile={isMobile}
        joinToWaitList={joinToWaitList}
        whiteListButtonText={whiteListButtonText}
      />
      <Footer />
      {isShowPopup && <ErrorPopup message={popupMessage}></ErrorPopup>}
    </PageContainer>
  );
}

const PopupDescriptionText = () => {
  return (
    <>
      It looks like you clicked a link that should open in Reach but Reach isn’t
      installed in this browser profile. Please re-install reach by clicking{" "}
      <a href="https://chromewebstore.google.com/detail/salesstreamai-reach/dpncdmdnpgoigoehmpkpblhdjkcdpaih">
        here
      </a>
    </>
  );
};

export default Landing;

const PageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.milk};
`;
