import { useEffect } from "react";
import { PrivacyPageContent } from "../components";
import { HUBSPOT_INSTALL_CONTENT } from "../utils/constants";

const HubspotAppInstructions = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    return;
  }, []);
  return (
    <PrivacyPageContent
      content={HUBSPOT_INSTALL_CONTENT}
      title="HubSpot Setup Instructions"
    />
  );
};

export default HubspotAppInstructions;
